<template>


  <div class="container">
    <div class="day card" style="padding:4px" v-for="(times, day) in timeList" :key="day">
      <div class="day-header">
        <div class="time-header row">
          <div class="col-8">
            <h3>{{ day }}</h3>
          </div>
          <div class="col-4">
            {{ times.sum }} h
          </div>
        </div>
        <div class="day-content row">
          <div class="time col-12" v-for="time in times.days" :key="time.id">
            <div class="time-header row">
              <div class="col-8">
                <h4 class="fw-bold">{{ time.from }} - {{ time.till }}</h4>
              </div>
              <div class="col-4">
                <template v-if="times.days.length > 1">{{ time.sum }} h</template>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>


import axios from "axios";
import {useToast} from 'vue-toastification';

export default {

  name: 'TimeList',
  data() {
    return {}
  },

  props: {
    timeList: {
      type: Array,
      required: true
    }
  },

}

</script>

