<template>



    <div class="mainHeader">

      <div class="row">

        <div class="col-12">

          <h1 class="text-uppercase text-center pt-2">{{ headline }} </h1>
          <h2 class="d-block text-center pt-2">{{ employee.data.employee.e_name }}</h2>

        </div>

      </div>

    </div>



</template>

<script>

export default {

  name: 'MainHeader',
  props: {

    headline: String,
    text: String,
    employee: {
      type: Object,
      default: null
    }

  }

}

</script>
<style lang="scss">

</style>
