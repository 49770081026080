<template>


  <div class="wrapperTime">



    <div class="dashboard2" v-if="step === 'time'">

      <MainHeader headline="Zeit erfassen" :employee="employee"  text=""/>

      <div class="container">

        <div class="row">
          <div class="col-lg-12 d-flex align-items-stretch mb-4">

            <div class="card h-100 w-100 flex-fill">

              <div class="card-body p-4 d-flex flex-wrap">


                <div class="row w-100 align-self-end w-100">
                  <div class="col-12">
                    <Datepicker class="mb-2" v-model="time.date" auto-apply/>
                    <Datepicker class="mb-2" v-model="time.start" time-picker auto-apply/>
                    <Datepicker v-model="time.end" time-picker auto-apply/>
                    <button @click="sendTime()" class="btn w-100 mt-3 btn-success">Zeit eintragen</button>
                  </div>
                </div>




              </div>

            </div>


          </div>
        </div>


      </div>
      <TimeList :timeList="timeList"/>
    </div>
    <div class="dashboard2" v-if="step === 'plan'">

      <MainHeader headline="Dienstplan" :employee="employee" text=""/>

      <div class="container">

        <div class="row">
          <div class="col-lg-4 d-flex align-items-stretch mb-4"  v-for="(times, weekDay) in employee.workingTimes" :key="weekDay">

            <div  class="card h-100 w-100 flex-fill">

              <div class="card-body p-4">


                <div class="workDay" >
                  <div class="row">
                    <div class="col"><h3>{{$t('weekday.'+weekDay)}}</h3></div>
                    <div class="col"><div style="text-align: right">{{times.sum}} h</div></div>
                  </div>



                  <div class="times">
                    <div class="time" v-for="time in times.items">
                      {{time.from}} - {{time.till}}
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>


      </div>
    </div>
    <div class="dashboard2" v-if="step === 'checkin'">

      <MainHeader headline="Check-In & Check-Out" :employee="employee" text=""/>


      <div class="container">

        <div class="row">
          <div class="col-lg-12 d-flex align-items-stretch mb-4">

            <div  class="card h-100 w-100 flex-fill">

              <div class="card-body p-4">

                <div class="row">
                  <div class="col-lg-12">
                  <p class="text-center mb-4 mt-4">
                   {{$t('checkin.types.'+checkinState.type)}}
                  </p>
                  </div>
                  <div class="col-lg-4" v-if="checkinState.type !== 'online'">
                <button class="w-100 mb-2 btn btn-success" @click="checkin('online')">Arbeit starten (Online)</button>
                  </div>
                  <div class="col-lg-4" v-if="checkinState.type !== 'dnd'">
                    <button class="w-100 mb-2 btn btn-warning" @click="checkin('dnd')">Arbeit starten (DND)</button>

                  </div>
                  <div class="col-lg-4" v-if="checkinState.type !== 'paused' && checkinState.type !== 'offline'">
                <button class="w-100 mb-2 btn btn-success" @click="checkin('paused')">Arbeit pausieren</button>

                </div>
                  <div class="col-lg-4" v-if="checkinState.type !== 'offline' && checkinState.type !== 'paused'">
                <button class="w-100 mb-2 btn btn-danger" @click="checkin('offline')">Arbeit beenden</button>
                </div>
              </div>

              </div>

            </div>


          </div>
        </div>


      </div>
    </div>
    <div class="fixedMenu">
      <button class="btn" :class="(step === 'time') ? 'btn-info' : 'btn-light'" @click="step = 'time'">Zeiten
      </button>
      <button class="btn" :class="(step === 'checkin') ? 'btn-info' : 'btn-light'" @click="step = 'checkin'">Stempeln
      </button>
      <button class="btn" :class="(step === 'plan') ? 'btn-info' : 'btn-light'" @click="step = 'plan'">Dienstplan
      </button>
    </div>
  </div>

</template>

<script>

import MainHeader from "@/components/MainHeader";
import {mapActions} from "vuex";
import environment from "@/auth/environment"
import axios from "axios";
import {useToast} from 'vue-toastification';
import TimeList from "@/views/subs/TimeList";

export default {

  name: 'DashboardView',
  components: {MainHeader, TimeList},
  data() {
    return {
      time: {
        date: '',
        start: '',
        end: '',

        comment: '',
      },
      alreadySentToday: false,
      projects: [],
      tasks: [],
      users: [],
      timeList: [],
      todayString: '',
      employee: {},
      step: 'checkin',
      checkinState: 'checkedOut',
    }
  },

  created() {

    this.time.start = {hours: 8, minutes: 0};
    this.time.end = {hours: 16, minutes: 0};
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    this.todayString = yyyy + '-' + mm + '-' + dd;
    this.time.date = this.todayString;
    this.getEmployee();
    this.getTimes();
    this.getCheckinState();

  },
  methods: {
    ...mapActions(["logout"]),
    getTimes() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(environment.apiUrl + "time/list", {headers}).then((response) => {
        this.timeList = response.data.result.data.returner;
        // get today Y-m-d

        this.alreadySentToday = !!(this.timeList[this.todayString]);
      }).catch((error) => {
        console.log(error);
      });

    }, getCheckinState() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(environment.apiUrl + "time/checkinState", {headers}).then((response) => {
        this.checkinState = response.data.result.data;

      }).catch((error) => {
        console.log(error);
      });

    },
    getEmployee() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(environment.apiUrl + "employee/"+this.$store.state.user.id, {headers}).then((response) => {
        this.employee = response.data.result;

      }).catch((error) => {
        console.log(error);
      });

    },
    sendTime() {
      this.alreadySentToday = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const toast = useToast();

      axios.post(environment.apiUrl + 'time', this.time, {headers})
        .then(response => {
          console.log(response);
          this.timeList = response.data.result.data.returner;
          toast.success("Zeit erfolgreich eingetragen");
        })
        .catch(error => {

          this.$store.dispatch("handleApiError", error)

        });
    },
    checkin($type) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const toast = useToast();

      axios.post(environment.apiUrl + 'time/checkin', {type:$type}, {headers})
        .then(response => {
         if (response.data.result.data) {
          this.checkinState = response.data.result.data;
           this.getTimes();
          toast.success(this.$t('checkin.types.'+$type));
         } else {
           toast.error("Aktion war nicht erfolgreich");
         }
        })
        .catch(error => {
          this.$store.dispatch("handleApiError", error)

        });
    }
  }
}

</script>
<style lang="scss">

.dp__pointer {
  text-indent: 24px;
  line-height: 24px;

}

.dp__cell_inner {
  text-indent: 0;
}

.day-header h3 {
  font-size: 14px;
}
.mainHeader, footer, .fixedMenu {
  background: #333 !important;
  color: #fff !important;
  padding: 12px;
}

.mainHeader h1 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0px;
}

.mainHeader h2 {
  font-weight: normal;
  font-size: 20px;
}

footer a {
  color: #fff !important;
}
.fixedMenu {
  text-align: center;
  z-index: 1000;
  padding: 12px;
  position: fixed;
  box-shadow: 0px 0px 10px #000;
  bottom: 0;
  width: 100%;
  background: white;
  .btn {
    min-width: 15%;
  }
}
.headerBigImage {
  background: url("https://sketch.media/images/slider/kaffeesw.jpg");
  background-size: cover;
  position: relative;

  z-index: 100;
  &:before {
    background-color: #000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 300;
    content: "";
    opacity: .55;
  }
  .container {
    position: relative;
    z-index: 301;



  }

}
.fixedMenu .btn {
  margin-right: 5px;
}

.day-content {
  font-size: 14px;
}

h3 {
}

.day-content h4 {
  font-size: 16px;
  font-weight: normal;
}

.day.card {
  margin-bottom: 24px;
  padding: 12px !important;
}

.row .col-4 {
  text-align: right;
}
</style>
