const dev = {

  apiUrl: 'https://api-time.schiffe-schnelltest.de/',
  version: '1.0.0'
};


const prod = {

  apiUrl: 'https://api-time.schiffe-schnelltest.de/',
  version: '1.0.6'

};

let config = dev;

if (process.env.NODE_ENV === 'production') {
  config = prod;
}



export default {
  // Add common config values here
  ...config
};
