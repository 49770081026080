<template>
  <div class="allWrap d-flex flex-column h-100">
    <div class="mainContent flex-shrink-0">
      <div class="navigation w-100">
        <div class="container">
          <div class="row">

            <div class="col-12 col-lg-7">

            </div>

          </div>
        </div>
      </div>

      <router-view></router-view>
    </div>
    <footer class="footer navbar mt-3" style="padding-bottom: 60px">
      <div class="w-100">
        <div class="row">
          <div class="col-12">
            <ul class="list-style-none p-0 m-0 text-center">
              <li class="d-inline-block me-3"><a target="_blank" href="https://sketch.media/datenschutz.html" class="text-decoration-none">Datenschutz</a></li>
              <li class="d-inline-block me-3"><a target="_blank" href="https://sketch.media/impressum.html" class="text-decoration-none">Impressum</a></li>
              <li class="d-inline-block me-3"><a target="_blank" href="https://sketch.media/kontakt.html" class="text-decoration-none">Kontakt</a></li>
            </ul>
            <p style="font-size: 12px; text-align: center; margin-top: 24px;"> Version {{$store.state.version}}<br>© <a target="_blank" href="https://sketch.media/">sketch.media GmbH</a><br>wir machen <a target="_blank" href="https://niederbayern.digital/">#niederbayern.digital</a></p>
          </div>
        </div>
      </div>
    </footer>


    <div class="newVersionTest" @click="$store.state.publishNewVersion = false;"
         v-if="$store.state.publishNewVersion">
      <div class="VersionWarningWrap">
        <h2 class="mb-3">Neue Version verfügbar!</h2>
        <a href="/" class="button btn btn-success mb-3">Hier klicken!</a>
      <p class="versionInfo">Version: {{ $store.state.version }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import environment from "./auth/environment"

import router from "@/router";
export default {

  name: 'App',
  computed: {
    ...mapGetters(['checkLogin', 'getUser'])
  },

  methods: {
    ...mapActions(['logout', "checkToken"]),
    toggleNav(){
      if(this.showNav === false){
        this.showNav = true;
    } else {
        this.showNav = false;
      }


    },
    logoutInline() {
      localStorage.removeItem('sketchManagerToken');
      this.$store.state.loggedInState = null
      this.$store.state.scope = { scope: null, write: false, loggedOut: true }
      this.$store.state.acl = null
      this.$store.state.user = null
      this.$store.state.currentProgress = 1
      this.$store.state.currentApplication = null
      this.$store.state.user = null
      router.push('/');
      window.location.href = environment.logoutUrlComplete;
    }

  },

  created() {
    this.checkToken();
  },
 data() {
    return {
      showNav: false,
      preferences: [
        {
          title: 'Systemrelevante Cookies',
          description: 'Diese Cookies sind für die Funktion der Website notwendig und können nicht deaktiviert werden.',
             items: [{ label: 'technische Cookies', value: 'technical', isRequired: true }],
        },
      ]
    }
  },

}
</script>
<style type="scss">
.mainContent {
  min-height: 70vh;
  background-color: #eee !important;
}
.container .card {
  margin-top: 24px;
}
button.showNavButton.d-inline-block.d-lg-none.btn.btn-primary.btn-sm.float-end {
  position: absolute;
  right: 12px;
  top: 23px;
}
.newVersionTest {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  top: 0;
  background: #ffffffe0;
  padding: 20px;
  text-align: center;
  padding-top: 10vh;
}
.ps {
  height: 60vh;
}
.file-uploads.btn {
  padding: 2px!important;
}
</style>
